export function getMousePos(e) {
  return { x: e.clientX, y: e.clientY }
}

export function getMouseDegrees(x, y, degreeLimit) {
  let dx = 0
  let dy = 0
  let xdiff
  let xPercentage
  let ydiff
  let yPercentage

  const w = { x: window.innerWidth, y: window.innerHeight }

  // Left (Rotates node left between 0 and -degreeLimit)
  // 1. If cursor is in the left half of screen
  if (x <= w.x / 2) {
    // 2. Get the difference between middle of screen and cursor position
    xdiff = w.x / 2 - x
    // 3. Find the percentage of that difference (percentage toward edge of screen)
    xPercentage = (xdiff / (w.x / 2)) * 100
    // 4. Convert that to a percentage of the maximum rotation we allow for the node
    dx = ((degreeLimit * xPercentage) / 100) * -1
  }

  // Right (Rotates node right between 0 and degreeLimit)
  if (x >= w.x / 2) {
    xdiff = x - w.x / 2
    xPercentage = (xdiff / (w.x / 2)) * 100
    dx = (degreeLimit * xPercentage) / 100
  }
  // Up (Rotates node up between 0 and -degreeLimit)
  if (y <= w.y / 2) {
    ydiff = w.y / 2 - y
    yPercentage = (ydiff / (w.y / 2)) * 100
    // Note that I cut degreeLimit in half when she looks up
    dy = ((degreeLimit * 0.5 * yPercentage) / 100) * -1
  }
  // Down (Rotates node down between 0 and degreeLimit)
  if (y >= w.y / 2) {
    ydiff = y - w.y / 2
    yPercentage = (ydiff / (w.y / 2)) * 100
    dy = (degreeLimit * yPercentage) / 100
  }
  return { x: dx, y: dy }
}
