import * as THREE from 'three'
import { getMouseDegrees } from 'utils/getMousePosition'
import lerp from 'utils/lerp'

/* eslint-disable no-param-reassign */
export default function moveNode(mouse, node, degreeLimit = 10) {
  const degrees = getMouseDegrees(mouse.current.x, mouse.current.y, degreeLimit)
  node.rotation.xD = lerp(node.rotation.xD || 0, degrees.y, 0.1)
  node.rotation.yD = lerp(node.rotation.yD || 0, degrees.x, 0.1)
  node.rotation.x = THREE.Math.degToRad(node.rotation.xD)
  node.rotation.y = THREE.Math.degToRad(node.rotation.yD)
}
