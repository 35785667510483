import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import * as THREE from 'three'
import { useLoader, useFrame } from 'react-three-fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import moveNode from 'utils/moveNode'
import lerp from 'utils/lerp'
import { lerpInterpolationValue } from './constants'

function Scene4({ position, mouse }) {
  const { animations, scene, nodes } = useLoader(
    GLTFLoader,
    'models/scene4.gltf',
    loader => {
      const dracoLoader = new DRACOLoader()
      dracoLoader.setDecoderPath('draco')
      loader.setDRACOLoader(dracoLoader)
    },
  )

  const group = useRef(null)

  const [mixer] = useState(() => new THREE.AnimationMixer())
  useFrame((state, delta) => {
    group.current.position.y = lerp(
      group.current.position.y,
      position.yAxis,
      lerpInterpolationValue,
    )
    group.current.scale.y = position.scale
    group.current.scale.x = position.scale
    group.current.scale.z = position.scale
    moveNode(mouse, nodes.Ladder)
    moveNode(mouse, nodes.Plugs)
    moveNode(mouse, nodes.Tube_1)
    moveNode(mouse, nodes.Cube_1)
    moveNode(mouse, nodes.Tank)
    moveNode(mouse, nodes.Floor)
    moveNode(mouse, nodes.Hole)
    moveNode(mouse, nodes.Tank_2)
    moveNode(mouse, nodes.Controls)
    moveNode(mouse, nodes.Chair)
    return mixer.update(delta)
  })

  useEffect(() => {
    animations.forEach(clip => {
      const currentClip = mixer.clipAction(clip, group.current)
      currentClip.clampWhenFinished = true
      currentClip.loop = THREE.LoopOnce
      currentClip.play()
    })

    group.current.scale.y = 0.5
    group.current.scale.x = 0.5
    group.current.scale.z = 0.5
    group.current.castShadow = true

    return () => animations.forEach(clip => mixer.uncacheClip(clip))
  }, [])

  return <primitive object={scene} dispose={null} ref={group} />
}

Scene4.propTypes = {
  position: PropTypes.object,
  mouse: PropTypes.object,
}

export default Scene4
