import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import * as THREE from 'three'
import { useLoader, useFrame } from 'react-three-fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import moveNode from 'utils/moveNode'
import {
  MOBILE,
  TABLET,
  DESKTOP,
  LARGE_SCREEN,
  DESKTOP_XL,
} from 'hooks/constants'

function Scene6({ position, mouse, device }) {
  const { animations, scene } = useLoader(
    GLTFLoader,
    'models/scene6.gltf',
    loader => {
      const dracoLoader = new DRACOLoader()
      dracoLoader.setDecoderPath('draco')
      loader.setDRACOLoader(dracoLoader)
    },
  )

  const group = useRef()

  function getYdelta() {
    switch (device) {
      case MOBILE:
        return 1
      default:
        return 0
    }
  }

  function getZAxis() {
    switch (device) {
      case MOBILE:
        return 0
      case TABLET:
      case DESKTOP:
      case LARGE_SCREEN:
      case DESKTOP_XL:
      default:
        return -1
    }
  }

  const [mixer] = useState(() => new THREE.AnimationMixer())
  useFrame((state, delta) => {
    group.current.position.y = position.yAxis + getYdelta()
    moveNode(mouse, group.current)
    return mixer.update(delta)
  })

  useEffect(() => {
    animations.forEach(clip => {
      mixer.clipAction(clip, group.current).play()
    })
    group.current.position.z = getZAxis()
    group.current.castShadow = true

    return () => animations.forEach(clip => mixer.uncacheClip(clip))
  }, [])

  return <primitive object={scene} dispose={null} ref={group} />
}

Scene6.propTypes = {
  position: PropTypes.object,
  mouse: PropTypes.object,
  device: PropTypes.string,
}

export default Scene6
