import { useEffect, useState } from 'react'
import {
  MOBILE,
  TABLET,
  DESKTOP,
  LARGE_SCREEN,
  DESKTOP_XL,
  breakPoints,
} from './constants'

function useDevice() {
  const [device, setDevice] = useState('')

  useEffect(() => {
    window.addEventListener('resize', onResize)
    onResize()
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  function onResize() {
    if (window.innerWidth < breakPoints.tablet) {
      setDevice(MOBILE)
    }
    if (
      breakPoints.tablet <= window.innerWidth &&
      window.innerWidth < breakPoints.desktop
    ) {
      setDevice(TABLET)
    }
    if (
      breakPoints.desktop <= window.innerWidth &&
      window.innerWidth < breakPoints.largeScreen
    ) {
      setDevice(DESKTOP)
    }
    if (
      breakPoints.largeScreen <= window.innerWidth &&
      window.innerWidth < breakPoints.desktopXL
    ) {
      setDevice(LARGE_SCREEN)
    }
    if (window.innerWidth >= breakPoints.desktopXL) {
      setDevice(DESKTOP_XL)
    }
  }

  return device
}

export default useDevice
