export const MOBILE = 'mobile'
export const TABLET = 'tablet'
export const DESKTOP = 'desktop'
export const LARGE_SCREEN = 'largeScreen'
export const DESKTOP_XL = 'desktopXL'
export const breakPoints = {
  tablet: 768,
  desktop: 992,
  largeScreen: 1200,
  compact: 320,
  mobile: 480,
  desktopXL: 1441,
}
