import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import * as THREE from 'three'
import { useLoader, useFrame } from 'react-three-fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import moveNode from 'utils/moveNode'
import {
  MOBILE,
  TABLET,
  DESKTOP,
  LARGE_SCREEN,
  DESKTOP_XL,
} from 'hooks/constants'
import lerp from 'utils/lerp'
import { lerpInterpolationValue } from './constants'

function Scene1({ position, mouse, device }) {
  const gltf = useLoader(GLTFLoader, 'models/scene1.glb')
  const { animations } = gltf
  const group = useRef(null)
  const actions = useRef(null)

  const [mixer] = useState(() => new THREE.AnimationMixer())

  useFrame((state, delta) => {
    group.current.position.y = lerp(
      group.current.position.y,
      position.yAxis + getYdelta(),
      lerpInterpolationValue,
    )
    moveNode(mouse, group.current)
    return mixer.update(delta)
  })

  function getYdelta() {
    switch (device) {
      case MOBILE:
        return 1.5
      default:
        return 0
    }
  }

  function getZAxis() {
    switch (device) {
      case MOBILE:
        return 1.2
      case TABLET:
      case DESKTOP:
      case LARGE_SCREEN:
      case DESKTOP_XL:
      default:
        return 0
    }
  }

  useEffect(() => {
    actions.current = {
      flame: mixer.clipAction(animations[0], group.current),
      rocket: mixer.clipAction(animations[1], group.current),
    }
    actions.current.flame.play()
    actions.current.rocket.play()
    group.current.position.z = getZAxis()
    group.current.castShadow = true

    return () => animations.forEach(clip => mixer.uncacheClip(clip))
  }, [animations, mixer])

  return <primitive object={gltf.scene} dispose={null} ref={group} />
}

Scene1.propTypes = {
  position: PropTypes.object,
  mouse: PropTypes.object,
  device: PropTypes.string,
}

export default Scene1
