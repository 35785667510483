import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import * as THREE from 'three'
import { useLoader, useFrame } from 'react-three-fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import moveNode from 'utils/moveNode'
import lerp from 'utils/lerp'
import { slowDeltaFactor, lerpInterpolationValue } from './constants'

function Scene3({ position, mouse }) {
  const { animations, scene, nodes } = useLoader(
    GLTFLoader,
    'models/scene3.gltf',
    loader => {
      const dracoLoader = new DRACOLoader()
      dracoLoader.setDecoderPath('draco/')
      loader.setDRACOLoader(dracoLoader)
    },
  )

  const group = useRef(null)
  const actions = useRef(null)

  const [mixer] = useState(() => new THREE.AnimationMixer())

  useFrame((state, delta) => {
    group.current.position.y = lerp(
      group.current.position.y,
      position.yAxis,
      lerpInterpolationValue,
    )
    group.current.scale.y = position.scale
    group.current.scale.x = position.scale
    group.current.scale.z = position.scale
    mixer.setTime(position.time)
    moveNode(mouse, nodes.Pencil, 50)
    return mixer.update(delta * slowDeltaFactor)
  })
  useEffect(() => {
    actions.current = {
      grid: mixer.clipAction(animations[0], group.current),
      lights: mixer.clipAction(animations[1], group.current),
      world: mixer.clipAction(animations[2], group.current),
    }
    group.current.castShadow = true

    return () => animations.forEach(clip => mixer.uncacheClip(clip))
  }, [])

  return <primitive object={scene} dispose={null} ref={group} />
}

Scene3.propTypes = {
  position: PropTypes.object,
  mouse: PropTypes.object,
}

export default Scene3
