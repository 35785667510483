import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import * as THREE from 'three'
import { useLoader, useFrame } from 'react-three-fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import {
  MOBILE,
  TABLET,
  DESKTOP,
  LARGE_SCREEN,
  DESKTOP_XL,
} from 'hooks/constants'

import lerp from 'utils/lerp'
import {
  slowDeltaFactor,
  lerpInterpolationValue,
  lerpInterpolationValueRocket,
} from './constants'

function parralax(start, end, progress) {
  return start + progress * Math.abs(start - end)
}

function Scene2({ position, device }) {
  const { animations, scene } = useLoader(GLTFLoader, 'models/scene2.gltf')

  const group = useRef(null)
  const actions = useRef(null)
  const threeObjects = useRef(null)

  function getScale() {
    switch (device) {
      case MOBILE:
        return 0.7
      case TABLET:
      case DESKTOP:
      case LARGE_SCREEN:
      case DESKTOP_XL:
      default:
        return 1
    }
  }

  const [mixer] = useState(() => new THREE.AnimationMixer())

  useFrame((state, delta) => {
    const parallaxDiff = parralax(-0.1, 0.3, position.time)

    group.current.position.y = lerp(
      group.current.position.y,
      position.yAxis - parallaxDiff,
      lerpInterpolationValue,
    )

    const planetRotationZ = lerp(
      threeObjects.current.planets.rotation.z,
      THREE.Math.degToRad(-90 + parralax(-20, 25, position.time)),
      lerpInterpolationValue,
    )

    const planetRotationX = lerp(
      threeObjects.current.planets.rotation.x,
      THREE.Math.degToRad(80 + parralax(-8, 0, position.time)),
      lerpInterpolationValueRocket,
    )

    threeObjects.current.planets.rotation.z = planetRotationZ
    threeObjects.current.planets.rotation.x = planetRotationX
    mixer.setTime(lerp(mixer.time, position.time, lerpInterpolationValueRocket))
    group.current.children[2].children[2].children[1].rotation.z += 0.001
    return mixer.update(delta * slowDeltaFactor)
  })

  useEffect(() => {
    actions.current = {
      smallRocket: mixer.clipAction(animations[0], group.current),
      world: mixer.clipAction(animations[1], group.current),
    }

    threeObjects.current = {
      planets: group.current.children[2],
    }

    actions.current.world.play()
    actions.current.smallRocket.play()
    group.current.scale.x = getScale()
    group.current.scale.y = getScale()
    group.current.scale.z = getScale()
    group.current.castShadow = true
    group.current.alpha = 0.1

    return () => animations.forEach(clip => mixer.uncacheClip(clip))
  }, [])

  return <primitive object={scene} dispose={null} ref={group} />
}

Scene2.propTypes = {
  position: PropTypes.object,
  device: PropTypes.string,
}

export default Scene2
