import React, { Suspense, useState, useEffect, useRef } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Canvas, useThree } from 'react-three-fiber'
import anime from 'animejs/lib/anime.es.js'
import ScrollMagic from 'scrollmagic'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { getMousePos } from 'utils/getMousePosition'
import useDevice from 'hooks/useDevice'
import {
  MOBILE,
  TABLET,
  DESKTOP,
  LARGE_SCREEN,
  DESKTOP_XL,
} from 'hooks/constants'
import DownArrow from 'images/icons/down-arrow.svg'
import _times from 'lodash.times'

import BackgroundSection from 'components/BackgroundSection'
import Layout from 'components/Layout'
import SEO from 'components/Seo'
import Scene1 from 'models/Scene1'
import Scene2 from 'models/Scene2'
import Scene3 from 'models/Scene3'
import Scene4 from 'models/Scene4'
import Scene5 from 'models/Scene5'
import Scene6 from 'models/Scene6'
import getTimelineDuration from 'utils/getTimelineDuration'
import { rendererPixelRatio, scrollMultipleFactor } from 'utils/constants'

const CameraController = () => {
  const { camera, gl } = useThree()
  useEffect(() => {
    const controls = new OrbitControls(camera, gl.domElement)
    controls.target.set(0, 5, 0)
    controls.maxPolarAngle = Math.PI / 2
    controls.minPolarAngle = Math.PI / 2
    controls.enablePan = false
    controls.enableRotate = false
    controls.enableZoom = false
    controls.update()

    return () => {
      controls.dispose()
    }
  }, [camera, gl])
  return null
}

const IndexPage = () => {
  const container = useRef(null)
  const scene2Node = useRef(null)
  const [top, setTop] = useState(0)
  const scrollArea = useRef()
  const totalScenes = 6

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setTop(window.scrollY / scrollMultipleFactor)
    })
  }, [])

  const [visibility, setVisible] = useState('scene1')

  const [position] = useState({
    scene1: {
      yAxis: 3,
    },
    scene2: {
      yAxis: 0,
      time: 0,
    },
    scene3: {
      yAxis: -1,
      scale: 0.5,
    },
    scene4: {
      scale: 0.0001,
      time: 0,
      yAxis: 5,
    },
    scene5: {
      time: 0,
      yAxis: 0,
    },
    scene6: {
      yAxis: 0,
    },
    scene7: {
      yAxis: 0,
    },
  })

  const scenes = {
    scene1: {
      key: 'scene1',
      name: 'scene1',
      component: Scene1,
      targets: position.scene1,
      yAxis: 7,
      duration: 800,
      autoplay: false,
    },
    scene2enter: {
      key: 'scene2enter',
      name: 'scene2',
      component: Scene2,
      targets: position.scene2,
      yAxis: 5,
      duration: 300,
      autoplay: false,
    },
    scene2: {
      key: 'scene2',
      name: 'scene2',
      component: Scene2,
      targets: position.scene2,
      time: 1.7,
      duration: 400,
      autoplay: false,
    },
    scene2exit: {
      key: 'scene2exit',
      name: 'scene2',
      component: Scene2,
      targets: position.scene2,
      yAxis: 11,
      duration: 250,
      delay: 50,
      autoplay: false,
    },
    scene3entry: {
      key: 'scene3entry',
      name: 'scene3',
      component: Scene3,
      yAxis: 5,
      targets: position.scene3,
      duration: 500,
      autoplay: false,
    },
    scene3: {
      key: 'scene3',
      name: 'scene3',
      component: Scene3,
      scale: 0.0001,
      targets: position.scene3,
      duration: 500,
      delay: 200,
      autoplay: false,
    },
    scene4enter: {
      key: 'scene4enter',
      name: 'scene4',
      component: Scene4,
      targets: position.scene4,
      scale: 0.5,
      duration: 300,
      autoplay: false,
    },
    scene4: {
      key: 'scene4',
      name: 'scene4',
      component: Scene4,
      targets: position.scene4,
      yAxis: 12,
      duration: 500,
      delay: 200,
      autoplay: false,
    },
    scene5enter: {
      key: 'scene5enter',
      name: 'scene5',
      component: Scene5,
      targets: position.scene5,
      yAxis: 4.5,
      duration: 300,
      autoplay: false,
    },
    scene5: {
      key: 'scene5',
      name: 'scene5',
      component: Scene5,
      targets: position.scene5,
      time: 5.8,
      duration: 1200,
      autoplay: false,
    },
    scene5exit: {
      key: 'scene5exit',
      name: 'scene5',
      component: Scene5,
      targets: position.scene5,
      yAxis: 9,
      duration: 400,
      autoplay: false,
    },
    scene6: {
      key: 'scene6',
      name: 'scene6',
      component: Scene6,
      targets: position.scene6,
      yAxis: 3.1,
      duration: 400,
      autoplay: false,
    },
    // scene6exit: {
    //   key: 'scene6exit',
    //   name: 'scene6',
    //   component: Scene6,
    //   targets: position.scene6,
    //   yAxis: 10,
    //   duration: 400,
    //   delay: 200,
    //   autoplay: false,
    //   easing: 'easeOutSine',
    // },
    // scene7: {
    //   key: 'scene7',
    //   name: 'scene7',
    //   component: Scene6,
    //   targets: position.scene7,
    //   yAxis: 3,
    //   duration: 200,
    //   autoplay: false,
    //   easing: 'easeOutSine',
    // },
  }

  useEffect(() => {
    const timeLine = anime
      .timeline({
        duration: getTimelineDuration(scenes),
        autoplay: false,
        loop: true,
        easing: 'easeOutSine',
      })
      .add({
        ...scenes.scene1,
        changeBegin() {
          setVisible('scene1')
        },
      })
      .add({
        ...scenes.scene2enter,
        changeBegin() {
          setVisible('scene2')
        },
      })
      .add({
        ...scenes.scene2,
        changeBegin() {
          setVisible('scene2')
        },
      })
      .add({
        ...scenes.scene2exit,
        changeBegin() {
          setVisible('scene2')
        },
      })
      .add({
        ...scenes.scene3entry,
        changeBegin() {
          setVisible('scene3')
        },
      })
      .add({
        ...scenes.scene3,
        changeBegin() {
          setVisible('scene3')
        },
      })
      .add({
        ...scenes.scene4enter,
        changeBegin() {
          setVisible('scene4')
        },
      })
      .add({
        ...scenes.scene4,
        changeBegin() {
          setVisible('scene4')
        },
      })
      .add({
        ...scenes.scene5enter,
        changeBegin() {
          setVisible('scene5')
        },
      })
      .add({
        ...scenes.scene5,
        changeBegin() {
          setVisible('scene5')
        },
      })
      .add({
        ...scenes.scene5exit,
        changeBegin() {
          setVisible('scene5')
        },
      })
      .add({
        ...scenes.scene6,
        changeBegin() {
          setVisible('scene6')
        },
      })
    // .add({
    //   ...scenes.scene6exit,
    //   changeBegin() {
    //     setVisible('scene6')
    //   },
    // })
    // .add({
    //   ...scenes.scene7,
    //   changeBegin() {
    //     setVisible('scene7')
    //   },
    // })

    const scene = new ScrollMagic.Scene({
      duration:
        (container.current.offsetHeight / totalScenes) *
        (totalScenes - 1) *
        scrollMultipleFactor,
    }).on('progress', event => {
      timeLine.seek(timeLine.duration * event.progress)
    })

    const controller = new ScrollMagic.Controller()
    controller.addScene(scene)
  }, [])

  const device = useDevice()
  const width = typeof window !== 'undefined' && window.innerWidth
  const height = typeof window !== 'undefined' && window.innerHeight

  const aspect = width / height
  const near = 0.1
  const far = 1000

  const mouse = useRef({ x: 0, y: 0 })

  function onMouseMove(event) {
    mouse.current = getMousePos(event)
  }

  function renderScene() {
    const sceneList = Object.values(scenes)
    const filteredScene = sceneList.filter(scene => visibility === scene.name)
    return filteredScene.map(scene => {
      const { name, key } = scene
      return (
        <scene.component
          mouse={mouse}
          key={key}
          position={position[name]}
          device={device}
        />
      )
    })
  }

  function renderEmptyDivs() {
    return _times(16, index => (
      <div key={index} id={index} className="empty-div" />
    ))
  }

  function onDownArrowClick() {
    window.scrollTo({
      top: scene2Node.current.offsetTop * scrollMultipleFactor,
      behavior: 'smooth',
    })
  }

  function getFOV() {
    switch (device) {
      case MOBILE:
        return 80
      case TABLET:
      case DESKTOP:
        return 70
      case LARGE_SCREEN:
      case DESKTOP_XL:
      default:
        return 40
    }
  }

  const { backgroundImg } = useStaticQuery(
    graphql`
      query {
        backgroundImg: file(relativePath: { eq: "background.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  )

  return (
    <Layout dark>
      <SEO />
      <BackgroundSection image={backgroundImg} className="canvasContainer">
        <Canvas
          className="contextCanvas"
          camera={{
            fov: getFOV(),
            aspect,
            near,
            far,
            position: [-1, 0, 0],
          }}
          onCreated={({ gl }) => {
            gl.setPixelRatio(rendererPixelRatio)
          }}
        >
          <CameraController />
          <Suspense fallback={null}>{renderScene()}</Suspense>
        </Canvas>
      </BackgroundSection>
      <div className="scrollArea" ref={scrollArea}>
        {renderEmptyDivs()}
      </div>
      <div
        className="container --home"
        onMouseMove={onMouseMove}
        ref={container}
        style={{
          top: `-${top}px`,
        }}
      >
        <section className="home__section hero">
          <div className="home__body">
            <h2 className="heading">We ship products and companies</h2>
            <p className="text">
              With over a decade of experience, our team works with select
              founders and Fortune 500 companies who are mission-driven and
              user-obsessed to launch products that solve problems with
              technology.
            </p>
            <div className="actions">
              <a
                className="primary-button"
                href="mailto:hello@pbjapps.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon" aria-label="hello" role="img">
                  ✏️
                </span>
                Get in touch
              </a>
            </div>
          </div>
          <button
            className="down-arrow"
            type="button"
            onClick={onDownArrowClick}
          >
            <DownArrow />
          </button>
        </section>
        <section className="home__section center" ref={scene2Node}>
          <div className="home__body">
            <h4 className="sub-heading center">
              We understand the bigger picture
            </h4>
            <p className="text center">
              Above all else, we focus on finding product market fit. We build
              the right team to identify an existing problem, and then work to
              solve it for your users.
            </p>
          </div>
        </section>
        <section className="home__section center">
          <div className="home__body">
            <h4 className="sub-heading center">Laying the Foundation</h4>
            <p className="text center">
              Together we’ll identify and build only what’s necessary, enabling
              us to get to market faster, iterate, and scale.
            </p>
          </div>
        </section>
        <section className="home__section center sm-center">
          <div className="home__body">
            <h4 className="sub-heading center">Bringing it to Life</h4>
            <p className="text center">
              Through a series of sprints, we build features in shippable
              increments which allow us to test often, learn quickly, and
              respond to the market.
            </p>
          </div>
        </section>
        <section className="home__section">
          <div className="home__body">
            <h4 className="sub-heading">Final Touches</h4>
            <p className="text center-small">
              After defining KPIs and metrics to measure success, we’ll
              incorporate the right reporting and analytic stack to make data
              driven decisions.
            </p>
          </div>
        </section>
        <section className="home__section">
          <div className="home__body">
            <h4 className="sub-heading">Scaling Together</h4>
            <p className="text center-small">
              Whether it’s continued feature development, scaling user bases,
              hiring in-house talent, or basic support, we’ll guide you from MVP
              through your next stage of growth.
            </p>
            <a
              className="sign-up"
              href="mailto:hello@pbjapps.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon" aria-label="hello" role="img">
                👋
              </span>
              Say hello
            </a>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default IndexPage
