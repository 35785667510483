import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import * as THREE from 'three'
import { useLoader, useFrame } from 'react-three-fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import moveNode from 'utils/moveNode'
import {
  MOBILE,
  TABLET,
  DESKTOP,
  LARGE_SCREEN,
  DESKTOP_XL,
} from 'hooks/constants'
import lerp from 'utils/lerp'
import { slowDeltaFactor, lerpInterpolationValue } from './constants'

function Scene5({ position, mouse, device }) {
  const { animations, scene } = useLoader(
    GLTFLoader,
    'models/scene5.gltf',
    loader => {
      const dracoLoader = new DRACOLoader()
      dracoLoader.setDecoderPath('draco')
      loader.setDRACOLoader(dracoLoader)
    },
  )

  function getYdelta() {
    switch (device) {
      case MOBILE:
        return 1
      default:
        return 0
    }
  }

  function getZAxis() {
    switch (device) {
      case MOBILE:
        return 0
      case TABLET:
      case DESKTOP:
      case LARGE_SCREEN:
      case DESKTOP_XL:
      default:
        return -1
    }
  }

  const group = useRef()

  const [mixer] = useState(() => new THREE.AnimationMixer())

  useFrame((state, delta) => {
    group.current.position.y = lerp(
      group.current.position.y,
      position.yAxis + getYdelta(),
      lerpInterpolationValue,
    )
    mixer.setTime(position.time)
    moveNode(mouse, group.current)
    return mixer.update(delta * slowDeltaFactor)
  })

  useEffect(() => {
    animations.forEach(clip => {
      mixer.clipAction(clip, group.current).play()
    })

    group.current.scale.y = 0.5
    group.current.scale.x = 0.5
    group.current.scale.z = 0.5

    group.current.position.z = getZAxis()
    group.current.castShadow = true

    return () => animations.forEach(clip => mixer.uncacheClip(clip))
  }, [])

  return <primitive object={scene} dispose={null} ref={group} />
}

Scene5.propTypes = {
  position: PropTypes.object,
  mouse: PropTypes.object,
  device: PropTypes.string,
}

export default Scene5
